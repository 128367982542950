<template>
    <div id="resources">
        <mainheader></mainheader>
        <ChooseTariffModal></ChooseTariffModal>
        <mainbox></mainbox>
    <section>
        <div class="recources-block">
            <div class="container">
                    <h1>{{ translation.translate('Resources','resources-title') }}</h1>
                    <p class="information-main">{{ translation.translate('Resources','resources-info') }}</p>
                <div class="row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/Android.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title">{{ translation.translate('Resources','Mobile-app') }}</p>
                            <p class="appBox-info">(Android)</p>
                            <p class="version-info">v 1.0.13</p>
                            </div>
                            <div class="item-btn-app">
                                <a href="https://edo.faktura.uz/resource/Faktura Play Market.zip" download="Faktura Android.zip" class="btn-download-appBox">
                                {{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/apple.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title">{{ translation.translate('Resources','Mobile-app') }}</p>
                            <p class="appBox-info">(IOS)</p>
                            <p class="version-info">v 1.0.3</p>
                            </div>
                            <div class="item-btn-app">
                                <a target="_blank" href="https://apps.apple.com/us/app/faktura/id1454146398" class="btn-download-appBox">{{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/anydesk.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title fz-14">Anydesk - <span>{{ translation.translate('Resources', 'remote-access') }}</span></p>
                            <p class="appBox-info">(Windows)</p>
                            <p class="version-info">v 6.2.3</p>
                            </div>
                            <div class="item-btn-app">
                                <a href="https://edo.faktura.uz/resource/AnyDesk Windows.zip" class="btn-download-appBox">{{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/anydesk.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title fz-14">Anydesk - <span>{{ translation.translate('Resources', 'remote-access') }}</span></p>
                            <p class="appBox-info">(MacOS)</p>
                            <p class="version-info">v 6.1.4</p>
                            </div>
                            <div class="item-btn-app">
                                <a href="https://edo.faktura.uz/resource/AnyDesk MacOS.zip" class="btn-download-appBox">{{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/sinx.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title ">Chrome <span>{{ translation.translate('Resources', 'extension') }}</span></p>
                            <p class="version-info">v 1.0.0</p>
                            </div>
                            <div class="item-btn-app">
                                <a href="https://edo.faktura.uz/resource/Faktura-Sync.zip" class="btn-download-appBox">{{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/eimzo.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title">{{ translation.translate('Resources', 'signature') }}</p>
                            <p class="appBox-info">(Windows)</p>
                            <p class="version-info">v 3.41</p>
                            </div>
                            <div class="item-btn-app">
                                <a href="https://edo.faktura.uz/resource/E-IMZO windows.zip" class="btn-download-appBox">{{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div class="app-box">
                            <div class="item-img">
                                <img src="../assets/images/app-box/eimzo.png" alt="png">
                            </div>
                            <div class="item-text">
                                <p class="appBox-title">{{ translation.translate('Resources', 'signature') }}</p>
                            <p class="appBox-info">(Android)</p>
                            <p class="version-info">v 1.6</p>
                            </div>
                            <div class="item-btn-app">
                                <a href="https://edo.faktura.uz/resource/e-imzo_1.6.zip" class="btn-download-appBox">{{ translation.translate('Resources', 'download-btn') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
  </section>
        <mainfooter></mainfooter>
    </div>
</template>


<script defer>
import translate from '../../translation/translate'
import mainbox from './mainbox.vue'
import mainheader from "./mainheader.vue"
import mainfooter from "./mainfooter.vue"
import ChooseTariffModal from './modal/ChooseTariffModal.vue'

export default {
  name: 'resources',
    data() {
        return {
            translation: translate,
        }
    },
  components: {
    mainheader,
    mainbox,
    mainfooter,
    ChooseTariffModal
},
   metaInfo() {
        return { 
            title: "Видеоуроки по пользованию системой эдо Faktura.EDO",
            meta: [
                { name: 'description', content:  'Видеоролик где подробно описывается все взаимодействие с системой электронного документооборота Faktura.EDO Остались вопросы? Звоните ☎️: +998 (71) 200-00-13'},
            ]
        }
    },
}
</script>

<style>

</style>


